import React from 'react'
import {ItemEditor} from '../components/ItemEditor'
import { useLocation } from 'react-router-dom'
import qs from 'query-string'
import {fetch} from "../api/product";
import {signInRequired} from '../auth/auth'
import {APIConfig} from '../config/aws'

const Editor = (props)=>{

    const [product, setProduct] = React.useState({
        details:{},
        images:[],
        resource:[],
        imageBaseUrl:APIConfig.DEFAULT_IMAGE_URL
    })
    let location = useLocation();

    const load = async (id)=>{
        const {data} = await fetch({id})
        if(data)
            setProduct(data)
    }

    React.useEffect(()=>{
        const {id} = qs.parse(location.search);
        if(id){
            load(id)
        }
    },[location.search])

    return <React.Fragment>
        <ItemEditor {...product}/>
    </React.Fragment>
}

export default signInRequired(Editor,'/signIn');