import React from 'react'
import {Upload, Button} from "antd";
import {PlusOutlined,UploadOutlined} from "@ant-design/icons";
import {uploadImage,uploadProduct} from "../api/product";


export const S3Image = ({productId,subKey,count=1,uploadComplete,onRemove})=>{

    const [opt,setOpt] = React.useState({
        fileList:[]
    })

    return <React.Fragment>
        <Upload
            accept=".jpg,.jpeg,.png"
            customRequest ={async ({onSuccess, onError, file}) => {
                const {data:{url,key,src}} = await uploadImage({productId,subKey})
                const res  = await fetch(url, {
                    method: 'PUT',
                    headers:{
                        "Content-Type": 'multipart/form-data'
                    },
                    body: file
                })
                if(uploadComplete){
                    uploadComplete({key,src})
                }
                onSuccess(res, file)
            }}
            listType="picture-card"
            fileList={opt.fileList}

            onPreview={() => {

            }}
            onRemove={onRemove}
            onChange={({fileList}) => {
                setOpt({...opt, fileList})
            }}
        >
            {opt.fileList.length >= count ? null : <PlusOutlined/>}
        </Upload>
    </React.Fragment>
}


export const S3Product = ({productId,subKey,onRemove})=>{

    const [opt,setOpt] = React.useState({
        fileList:[]
    })

    return <React.Fragment>
        <Upload
            accept=".zip"
            customRequest ={async ({onSuccess, onError, file}) => {
                const name = file.name.substring(0, file.name.lastIndexOf('.')) || file.name
                const {data:{url}} = await uploadProduct({productId,subKey:name,size:file.size});
                const res  = await fetch(url, {
                    method: 'PUT',
                    headers:{
                        "Content-Type": 'multipart/form-data'
                    },
                    body: file
                })
                onSuccess(res, file)
            }}
            defaultFileList={opt.fileList}
            listType="picture"
            onRemove={onRemove}
            onChange={(fileList)=>{
                setOpt({...opt,fileList})
            }}
        >
            <Button>
                <UploadOutlined/> Upload
            </Button>
        </Upload>
    </React.Fragment>
}

