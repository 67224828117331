import React from "react";
import {
    Button,
    Descriptions,
    PageHeader,
    Steps,
    Form,
    Row,
    Col,
    Input,
    Upload,
    Result,
    Image,
    InputNumber,
    Typography, Card, Space
} from "antd";
import {PlusOutlined,DeleteOutlined} from '@ant-design/icons'
import {S3Image,S3Product} from "./S3Image";
import {create, purgeResource} from '../api/product'
import moment from "moment";
import {v4} from 'uuid'
import {useHistory} from "react-router-dom";

export const DetailEdit  = ({form, onFinish})=>{

    return <React.Fragment>
        <Form
            validateMessages={{required: "${label} is required!"}}
            labelCol={{span:4}}
            form={form}
            name={`product-details`}
            onFinish={(v)=>{
                onFinish(v);
            }}
        >
            <Form.Item label={`Title`} name={"title"} rules={[{required:true}]}>
                <Input/>
            </Form.Item>
            <Form.Item
                label={`Price`}
                name={"price"}
                rules={[
                    {required:true}
                    ]}
            >
                <InputNumber
                    formatter={value => `$ ${value}`}
                    parser={value => value.replace('$ ', '')}
                    precision={2}
                    style={{width: '100%'}}
                />
            </Form.Item>
            <Form.Item label={`Summary   `} name={"summary"} rules={[{required:true}]}>
                <Input.TextArea allowClear={true} rows={4}/>
            </Form.Item>
            <Form.Item label={`Description`} name={"desc"} rules={[{required:true}]}>
                <Input.TextArea allowClear={true} rows={6}/>
            </Form.Item>
        </Form>
    </React.Fragment>
};
export const Thumbnail = ({product:{detail}})=>{
    console.log(`Thumbnail product id = ${detail}`)
    return <React.Fragment>
        <Row>
            <Col span={24}>
                <S3Image subKey={'thumbnail'} productId={detail}/>
            </Col>
        </Row>
    </React.Fragment>
}

export const ImagePair = ({productId, onUploadComplete,onDelete})=>{

    const [imageId,setImageId] = React.useState(v4());
    const [uploaded, setUploaded] = React.useState({})

    React.useEffect(()=>{
        setImageId(v4())
        setUploaded(0)
    },[productId])

    return <React.Fragment>
        <Card
            title={`Pair #${imageId}`}
            extra={[
                <Button icon={<DeleteOutlined/>} onClick={(e)=>{
                    if(onDelete)
                        onDelete(imageId);
                }}/>
            ]}
            style={{width: 800}}
        >
            <Row>
            <Col span={6}/>
            <Col span={6}>
                <S3Image
                    productId={productId}
                    subKey={`${imageId}/before`}
                    uploadComplete={({key,src})=>{
                        if(uploaded.after && onUploadComplete){
                            onUploadComplete({...uploaded, before: src,imageId})
                        }
                        setUploaded({...uploaded, before: src});
                    }}
                />
            </Col>
            <Col span={6}>
                <S3Image
                    productId={productId}
                    subKey={`${imageId}/after`}
                    uploadComplete={({key,src})=>{
                        if(uploaded.before && onUploadComplete){
                            onUploadComplete({...uploaded, after: src,imageId})
                        }
                        setUploaded({...uploaded, after: src});
                    }}
                />
            </Col>
            <Col span={6}/>
        </Row>
        </Card>
    </React.Fragment>
}


export const Images = ({details = {}, images = [],imageBaseUrl})=>{
    const items = images.reduce((acc,{subkey,path})=>{
        const [key, type] = subkey.split('/');
        acc[key] = {...acc[key],[type]: path}
        return acc;
    },{});
    const [imageList,setImageList] = React.useState({...items});

    const [addImage, setAddImage] = React.useState(true)

    const nodes = Object.entries(imageList).map(([key, {before,after}],i)=>{
        return <React.Fragment>
            <Card
                title={`Pair #${key}`}
                extra={[
                    <Button icon={<DeleteOutlined/>} onClick={async ()=>{
                        await purgeResource({productId: details.detail, type:'image',identifier: key})
                        const {[key]:item, ...args} = imageList;
                        setImageList({...args})
                    }}/>
                ]}
                style={{width: 800}}
            >
                <Space>
                   <Image.PreviewGroup>
                       <Image width={350} src={`${imageBaseUrl}/${before}`}/>
                       <Image width={350} src={`${imageBaseUrl}/${after}`}/>
                   </Image.PreviewGroup>
                </Space>
            </Card>
        </React.Fragment>
    })

    return <Space direction={'vertical'}>
        {nodes}
        {(!addImage) && <ImagePair
            productId={details.detail}
            onDelete={async (key)=>{
                await purgeResource({productId: details.detail, type:'image',identifier: key})
                setAddImage(true)
            }}
            onUploadComplete={({before, after,imageId})=>{
            setImageList({...imageList,[imageId]:{ before, after }})
            setAddImage(true)
        }}/>}
        {addImage && <Button
            type="dashed"
            block
            style={{width: '100%'}}
            onClick={() => {
                setAddImage(false)
            }}
        >
            <PlusOutlined/>
            <Typography.Text>Add Image Pair</Typography.Text>
        </Button>}
    </Space>
}

export const UploadProduct = ({product:{detail:productId}})=>{
    return <React.Fragment>
        <S3Product productId={productId} subKey={'windows'} onRemove={async (file)=>{
            const name = file.name.substring(0, file.name.lastIndexOf('.')) || file.name
            await purgeResource({productId, type:'resource',identifier: name})
        }}/>
    </React.Fragment>
}

export const Published = ({onHome, onNew})=>{
    return <React.Fragment>
        <Result
            status="success"
            title="Product Created Successfully"
            extra={
               [
                   <Button onClick={onHome}>Home</Button>,
                   <Button onClick={onNew} type="primary">Create</Button>
               ]
            }
        />
    </React.Fragment>
}



export const ItemEditor = ({details = {}, images = [], resource = [],imageBaseUrl})=>{
    const [opt,setOpt] = React.useState({
        step: 0,
        details
    })

    const history = useHistory();

    const [form] = Form.useForm();

    React.useEffect(()=>{
        setOpt({...opt, details })
        form.setFieldsValue(details)
    },[details])


    return <React.Fragment>
        <PageHeader
            title={`Create Product`}
            extra={[
                <Button disabled={opt.step === 0  || opt.step === 4} key="2" onClick={async (e)=>{
                    e.stopPropagation();
                    setOpt({...opt,step: opt.step - 1 })
                }}>Back</Button>,
                <Button disabled={opt.step === 4} onClick={async (e)=>{
                    e.stopPropagation();
                    if(opt.step === 0)
                        form.submit();
                    else
                        setOpt({...opt,step:opt.step + 1})
                }} key="1" type="primary">{opt.step < 3 ? `Next`: `Publish`}</Button>,
            ]}
        >
            <Descriptions size="small" columns={3}>
                <Descriptions.Item label={"Product ID"}>{opt.details.detail || 'N/A'}</Descriptions.Item>
                <Descriptions.Item label={"Created"}>{moment.unix(opt.details.date).format('YYYY-MM-DD')}</Descriptions.Item>
                <Descriptions.Item label={"Available"}>{true}</Descriptions.Item>
            </Descriptions>
            <Steps current={opt.step}>
                <Steps.Step title="Product Information"/>
                <Steps.Step title="Product Thumbnail"/>
                <Steps.Step title="Product Images"/>
                <Steps.Step title="Product Files"/>
            </Steps>
        </PageHeader>
        <Row gutter={16}>
            <Col span={2}/>
            <Col span={20} style={{textAlign:"center"}}>
                {opt.step === 0 && <DetailEdit form={form} onFinish={async (values)=>{
                    console.log(values)
                    const {data} = await create({data: {...details, ...values}})
                    setOpt({...opt, details: {...data },step: 1})
                }}/>}
                {opt.step === 1 && <Thumbnail product={ opt.details} imageBaseUrl={imageBaseUrl}/>}
                {opt.step === 2 && <Images details={opt.details} images={images} imageBaseUrl={imageBaseUrl}/>}
                {opt.step === 3 && <UploadProduct product={opt.details}/>}
                {opt.step === 4 && <Published onHome={()=>{
                    history.push('/')
                }} onNew={()=>setOpt({...opt,details: {},step: 0})}/>}
            </Col>
            <Col span={2}/>
        </Row>

    </React.Fragment>
}

export default ItemEditor;