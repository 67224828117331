/*global process*/
import axios from 'axios'
import {Auth} from 'aws-amplify'
import {notification} from "antd";
import {APIConfig} from '../config/aws'
/**/
if(process.env.REACT_APP_SERVER_URL){
    axios.defaults.baseURL = process.env.REACT_APP_SERVER_URL;
    axios.defaults.headers.common['Access-Control-Allow-Origin'] = '*';
    axios.defaults.headers.common['Access-Control-Allow-Headers'] = '*';
    axios.defaults.headers.post['Content-Type'] ='application/x-www-form-urlencoded';
}else{
    axios.defaults.baseURL = APIConfig.URL;
    axios.defaults.headers.common['Access-Control-Allow-Origin'] = '*';
    axios.defaults.headers.common['Access-Control-Allow-Headers'] = '*';
    axios.defaults.headers.post['Content-Type'] ='application/x-www-form-urlencoded';
}

axios.interceptors.request.use(async (cfg)=>{
    const session = await Auth.currentSession();
    const token = await session.getAccessToken();
    cfg.headers['Authorization'] = `Bearer ${token.getJwtToken()}`
    return cfg;
})

axios.interceptors.response.use(async (resp)=>{
    if(resp.status !== 200){
        notification.error({message: resp.statusText})
    }
    return resp;
})

export const create = async (data)=>{
    return axios.post("/api/product",data)
}
export const fetch = async (params)=>{
    return axios.get("/api/products",{
        params:params
    })
}

export const thumbnail = async ({productId,ext="jpg",content_type='image/jpeg'})=>{
    return axios.post("/api/product/upload",{productId,
        subKey:"thumbnail",
        type: 'image',
        ext,
        content_type})
}

export const uploadImage = async ({subKey,productId,ext="jpg",content_type='image/jpeg'})=>{
    return axios.post("/api/product/upload",{productId,
        subKey,
        type: 'image',
        ext,
        content_type})
}


export const uploadProduct = async ({productId,subKey,content_type='application/zip',ext='zip',size})=>{
    return axios.post("/api/product/upload",{productId,
        subKey,
        type:"resource",
        ext,
        content_type,
        size})
}

export const purgeResource = async ({productId, type, identifier})=>{
    return axios.post('/api/product/resource/purge', {productId,type,identifier})
}



