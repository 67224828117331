export const config = Object.freeze({
    Auth: {
        mandatorySignIn: true,
        region: 'us-east-1',
        userPoolId: 'us-east-1_gdXx9tzha',
        userPoolWebClientId: '1a48iel8r3i1s6rqttdi9defss',
    }
});

export const APIConfig = Object.freeze({
    URL: 'https://marketplace-api.phantomrndsolutions.com',
    DEFAULT_IMAGE_URL: 'http://terraform-20210119174846849200000003.s3-website-us-east-1.amazonaws.com'
})

export const authProps = Object.freeze({
    //usernameAlias:"email",
});