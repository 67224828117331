import React from 'react'
import {signInRequired} from "../auth/auth";
import {Button} from "antd";
import {useHistory} from "react-router-dom";
import {AmplifySignOut} from "@aws-amplify/ui-react";

export const MainViewImpl = (props)=>{
    const history = useHistory();

    return <React.Fragment>
        <Button onClick={()=>{
            history.push('/create')
        }}>Create</Button>
        <AmplifySignOut/>
    </React.Fragment>
}

export const MainView = signInRequired(MainViewImpl, '/signIn')