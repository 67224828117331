import React from 'react'
import logo from './logo.svg';
import './App.css';
import {authProps} from './config/aws'
import {MainView} from "./views/MainView";
import {SignInView} from "./auth/auth";
import 'antd/dist/antd.css'
import {
  BrowserRouter as Router,
  Switch,
  Route
} from "react-router-dom";
import {Images} from "./components/ItemEditor";
import Editor from "./views/Editor";

function App() {
  return <React.Fragment>
    <Router>
      <Switch>
        <Route path="/signIn">
          <SignInView {...authProps}/>
        </Route>
        <Route path="/create">
          <Editor/>
        </Route>
        <Route exact path={'/'}>
          <MainView/>
        </Route>
      </Switch>
    </Router>
  </React.Fragment>;
}

export default App;
